<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h4 class="mb-2">Dati</h4>
    <v-row>
      <FormItem v-model="name" rules="required" name="Nome Allegato" :lg="4" />
      <!-- Disallow changing the linked file in editing -->
      <v-col v-if="!edit" :cols="12" :sm="12" :md="4" :lg="4">
        <v-file-input
          v-model="file"
          label="Seleziona file da caricare"
          rule="size:10000"
          show-size
          filled
          clearable
          hint="E' possibile caricare immagini, documenti ed archivi con una dimensione massima di 10MB."
          persistent-hint
        >
        </v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <FormItem
        v-model="description"
        rules="required"
        name="Descrizione"
        type="textarea"
        :rows="2"
        :cols="2"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="12"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'virtualAgentsAttachments/getCurrent',
  mutationType: 'virtualAgentsAttachments/SET_CURRENT_FIELDS',
})

export default {
  name: 'AttachmentForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    statuses: [],
  }),
  computed: {
    ...authComputed,
    ...mapFields(['name', 'filename', 'description', 'type', 'file', 'extra']),
    ...mapState('virtualAgentsAttachments', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations('virtualAgentsAttachments', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('virtualAgentsAttachments', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
      this.reset()
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
