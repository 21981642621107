<template>
  <v-container fluid>
    <AttachmentsSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Elenco Allegati"
      module="virtualAgentsAttachments"
      :headers="headers"
      :actions="actions"
      sortby="created_at"
    >
      <template v-slot:item.size="{ item }">
        {{ item.size | filesize }}
      </template>
      <template v-slot:actions>
        <v-btn
          v-if="canUser('virtual_agents_mails', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-dialog
          v-model="dialog"
          max-width="75%"
          :fullscreen="$vuetify.breakpoint.mobile"
          :retain-focus="false"
          @click:outside="modalClose"
        >
          <v-card>
            <v-card-title>Gestione Allegati</v-card-title>
            <v-card-text>
              <VAAttachmentForm
                v-if="dialog"
                :edit="editMode"
                @submitted="onSubmitted"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogPreview"
          max-width="75%"
          :fullscreen="$vuetify.breakpoint.mobile"
          :retain-focus="false"
        >
          <v-card v-if="dialogPreview">
            <v-card-title>Anteprima</v-card-title>
            <v-card-text v-if="type === 'image'">
              <v-img :src="dialogPreviewUrl" contain />
            </v-card-text>
            <v-card-text v-else>
              <DocumentViewer
                width="100%"
                height="800px"
                :file-url="dialogPreviewUrl"
                :engine="type"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { mapActions, mapMutations } from 'vuex'
import FileSaver from 'file-saver'
import filesize from 'filesize'
import { authComputed } from '@state/helpers.js'
import VAAttachmentForm from '@components/virtualagents/attachment/AttachmentForm.vue'
import AttachmentsSectionSearch from '@components/virtualagents/attachment/AttachmentsSectionSearch.vue'
import DocumentViewer from '@components/common/DocumentViewer.vue'

export default {
  name: 'VirtualAgentAttachmentsSearch',
  page: {
    title: 'Attachments',
  },
  components: {
    BaseTable,
    VAAttachmentForm,
    DocumentViewer,
    AttachmentsSectionSearch,
  },
  filters: {
    filesize: function(value) {
      if (!value) return '-'
      return filesize(value)
    },
  },
  data() {
    return {
      dialog: false,
      dialogPreview: false,
      dialogPreviewUrl: null,
      type: null,
      editMode: false,
      headers: [
        {
          text: 'Nome Allegato',
          value: 'name',
        },
        {
          text: 'Nome File',
          value: 'filename',
        },
        {
          text: 'Descrizione',
          value: 'description',
        },
        {
          text: 'Dimensione file',
          align: 'start',
          value: 'size',
        },
        {
          text: 'Data caricamento',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Anteprima',
          icon: 'mdi-file-find',
          color: 'warning',
          onItemCondition: () =>
            this.canUser('virtual_agents_attachments', 'read'),
          handler: this.preview,
        },
        {
          label: 'Scarica',
          icon: 'mdi-download',
          color: 'red',
          onItemCondition: () =>
            this.canUser('virtual_agents_attachments', 'download'),
          handler: this.download,
        },
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () =>
            this.canUser('virtual_agents_attachments', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () =>
            this.canUser('virtual_agents_attachments', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('virtualAgentsAttachments', ['removeItem']),
    ...mapMutations('virtualAgentsAttachments', {
      setCurrent: 'SET_CURRENT',
      setScopes: 'SET_SCOPES',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),

    getViewerByType(type) {
      switch (type) {
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return 'office'

        case 'application/pdf':
          return 'google'

        case 'image/bmp':
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/svg+xml':
        case 'image/webp':
        case 'image/tiff':
          return 'image'

        default:
          return null
      }
    },

    preview(item) {
      this.dialogPreviewUrl = `${window.location.origin}${process.env.VUE_APP_BASE_URL}virtualagents/attachments/download/${item.hashed}?t=${this.currentUser.token}`

      this.type = this.getViewerByType(item.type)

      if (!this.type) {
        // no compatible type found
        this.$dialog.warning({
          text: 'Anteprima  per questo tipo di file non disponibile!',
          title: 'Attenzione',
        })
      }

      this.dialogPreview = true
    },

    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione dell'allegato ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      await this.$refs.table.refresh()
    },
    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
    download(item) {
      const url = `${process.env.VUE_APP_BASE_URL}virtualagents/attachments/download/${item.hashed}?t=${this.currentUser.token}`
      FileSaver.saveAs(url, item.filename)
    },
  },
}
</script>
