<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="name"
        type="text"
        name="Nome Allegato"
        hint="Ricerca per Nome Allegato"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="filename"
        type="text"
        name="Nome File"
        hint="Ricerca per Nome File"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="description"
        type="text"
        name="Descrizione"
        hint="Ricerca per nome descrizione"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'virtualAgentsAttachments/getFiltersFields',
  mutationType: 'virtualAgentsAttachments/SET_FILTER_FIELDS',
})

export default {
  name: 'AttachmentsSectionSearch',
  components: { FormItem, BaseSectionSearch },
  computed: {
    ...mapFields([
      'name',
      'filename',
      'description',
    ]),
    ...mapState('virtualAgentsAttachments', ['filters', 'types']),
  },
  mounted() {
    this.reset()
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('virtualAgentsAttachments', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
