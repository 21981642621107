<template>
  <v-sheet>
    <vue-friendly-iframe
      ref="iframeEl"
      :style="{ height }"
      :src="src"
      frameborder="0"
    ></vue-friendly-iframe>
  </v-sheet>
</template>

<script>
export default {
  name: 'DocumentViewer',
  components: {},
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '800px',
    },
    engine: {
      type: String,
      default: 'google',
    },
    fileUrl: {
      type: [String],
      required: true,
    },
  },
  data: () => ({
    iframeLoading: true,
  }),
  computed: {
    src() {
      if (this.engine === 'office')
        return `https://view.officeapps.live.com/op/embed.aspx?src=${this.fileUrl}`

      return `https://docs.google.com/gview?url=${this.fileUrl}&embedded=true`
    },
  },
  mounted() {
    setTimeout(() => {
      this.load = true
    }, 500)
  },
  methods: {
    onLoaded() {
      this.iframeLoading = false
    },
  },
}
</script>

<style>
.vue-friendly-iframe {
  width: 100%;
  height: 800px;
  z-index: 99999999;
}
.vue-friendly-iframe iframe {
  width: 100%;
  height: 100%;
}
</style>
